import React from "react";
import ReactDOM from "react-dom";
import "./screen.scss";

function Strand({ naam }) {
  return (
    <div>
      <h1>Ga zonnebaden in {naam}!</h1>
    </div>
  );
}

function SkiResort({ naam }) {
  return (
    <div>
      <h1>Ga heerlijk skiën in {naam}!</h1>
    </div>
  );
}

function App(props) {
  if (props.seizoen === "zomer") {
    return <Strand naam="Knokke" />;
  } else if (props.seizoen === "winter") {
    return <SkiResort naam="Val Thorens" />;
  }
}

ReactDOM.render(<App seizoen="winter" />, document.getElementById("root"));
